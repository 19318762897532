import React from "react"
import "./App.scss"
import Chart from "./charts/Chart"


function App() {
  return (
    <div className={"App"}>
      <Chart />
    </div>
  )
}

export default App