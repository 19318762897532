import { io } from 'socket.io-client'

function initSockets(url, channelToSubscription) {
  const path = getExchangeFromParams('exchange');
  const socket = io(url, {
    path: `/${path}`,
    reconnectionDelayMax: 10000,
    transports: ["websocket"]
  })

  socket.on('connect', () => {
    const cache = channelToSubscription.get('cache')
    if (cache) {
      // console.log(cache)
      socket.emit('subscribe', cache)
    }
  })

  socket.on('disconnect', (reason) => {
    console.log('[socket] Disconnected:', reason)
  })

  socket.on('error', (error) => {
    console.log('[socket] Error:', error)
  })

  socket.on('data', (data) => {
    const candle = JSON.parse(data).newCandle;
    const { time, ...rest } = candle;
    const callback = channelToSubscription.get('handler');
    if (!callback) return
    callback({ ...rest, time: Date.now() });
  })

  return socket
}
function getParams() {
  return new URLSearchParams(window.location.search);
}
function getExchangeFromParams(key) {
  return 'socket.io';
  // const queryParams = getParams();
  // return queryParams.get(key) || 'socket.io';
}
export default class {
  constructor() {
    const url = process.env.REACT_APP_BACKEND_URL || 'http://localhost:4040/';
    // const url = (exchangeParam.toUpperCase() === 'CDX') ? 'http://localhost:4041/' : 'http://localhost:4040/';
    this.exchangeParam = getParams().get('exchange') || 'CDX';
    // const url = (exchangeParam.toUpperCase() === 'CDX') ? 'http://45.79.127.70:4040/' : 'http://45.79.127.70:4041/';
    this.channelToSubscription = new Map()
    this.socket = initSockets(url, this.channelToSubscription)

  }

  getBars(info, callback) {
    this.socket.emit('getBars', { ...info, exchange: this.exchangeParam });
    this.socket.on('historic-bars', (data) => callback(JSON.parse(data)));
  }

  subscribeOnStream(
    symbolInfo,
    resolution,
    onRealtimeCallback,
    subscriberUID,
    onResetCacheNeededCallback,
    lastDailyBar,
  ) {
    const exchange = getParams().get('exchange') || 'cdx';
    const info = { symbolInfo, resolution, lastDailyBar, exchange }
    this.channelToSubscription.set('cache', info)
    this.channelToSubscription.set('handler', onRealtimeCallback)
    this.socket.emit('subscribe', info)
  }

  unsubscribeFromStream(subscriberUID) {
    // console.log(subscriberUID)
  }
}
