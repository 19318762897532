const ConfigData = {
  supported_resolutions: ['1', '5', '15', '1h', '1D', '1W'],
  // supported_resolutions: ['1', '5', '15', '1h', '1D', '1W', '1M'],

  exchanges: [
    {
      value: 'SunCrypto',
      name: 'SunCrypto',
      desc: 'SunCrypto exchange',
    },
  ],

  symbols_types: [
    {
      name: 'crypto',
      value: 'crypto',
    },
  ],
}

export default ConfigData
