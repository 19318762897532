import ConfigData from './ConfigData'
import Streaming from '../streaming/streaming'

const streaming = new Streaming();

const CustomDatafeed = {
  onReady: (cb) => {
    setTimeout(() => cb(ConfigData))
    // console.log('onReady:: CustomDatafeed')
    // Implement this method to initialize your data feed and call the callback function when ready.
  },

  searchSymbols: (userInput, exchange, symbolType, onResultReadyCallback) => {
    // console.log('SearchSymbol')
    // Implement this method to search for symbols based on user input and call the callback function with the search results.
  },

  // Set The Symbol info inside chart widget
  async resolveSymbol(
    symbolName,
    onSymbolResolvedCallback,
    onResolveErrorCallback,
    extension,
  ) {
    // console.log('symbolName', symbolName);
    const symbolInfo = {
      ticker: symbolName,
      name: symbolName,
      description: symbolName,
      type: 'crypto',
      session: '24x7',
      timezone: 'Asia/Kolkata', //indian timezone
      exchange: 'Sun Crypto',
      minmov: 1,
      pricescale: 100,
      supported_resolutions: ConfigData.supported_resolutions,
      visible_plots_set: { volume: false },

      has_intraday: true,
      has_weekly_and_monthly: true,
      volume_precision: 2,
      data_status: 'streaming',
    }

    setTimeout(() => onSymbolResolvedCallback(symbolInfo))
  },

  //get Historical bars with getBars
  getBars: (
    symbolInfo,
    interval,
    periodParams,
    onHistoryCallback,
    onErrorCallback,
  ) => {
    // need to multiply the timeframe with 1k
    const to = periodParams.to * 1000
    const from = periodParams.from * 1000

    const reqHeader = { symbol: symbolInfo['ticker'], from, to, interval }

    streaming.getBars(reqHeader, (data) => {
      // console.log('data', data);
      const { msg, bars } = data

      onHistoryCallback(bars, { noData: bars.length <= 0 })
    })
  },

  subscribeBars: (
    symbolInfo,
    resolution,
    onRealtimeCallback,
    subscriberUID,
    onResetCacheNeededCallback,
  ) => {
    streaming.subscribeOnStream(
      symbolInfo,
      resolution,
      onRealtimeCallback,
      subscriberUID,
      onResetCacheNeededCallback,
    )
    // Implement this method to subscribe to real-time updates for the specified symbol and resolution and call the appropriate callback function with the result or an error.
  },

  unsubscribeBars: (subscriberUID) => {
    // console.log(subscriberUID)
    streaming.unsubscribeFromStream()
    // Implement this method to unsubscribe from real-time updates for the specified subscriber.
  },

  calculateHistoryDepth: (resolution, resolutionBack, intervalBack) => {
    // console.log('calculate history depth')
    // Implement this method to calculate the required history depth for a given resolution and time interval and return the result.
  },

  getMarks: (symbolInfo, startDate, endDate, onDataCallback, resolution) => {
    // console.log('get marks')
    // Implement this method to fetch event markers data for the specified symbol and time range and call the appropriate callback function with the result or an error.
  },

  getTimeScaleMarks: (
    symbolInfo,
    startDate,
    endDate,
    onDataCallback,
    resolution,
  ) => {
    // console.log('get time scale marks')
    // Implement this method to fetch time scale markers data for the specified symbol and time range and call the appropriate callback function with the result or an error.
  },
}

window.UDFCompatibleDatafeed = CustomDatafeed

export default CustomDatafeed
